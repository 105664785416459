import * as React from "react"
import { StaticImage } from 'gatsby-plugin-image'
import Layout from "../../components/layout"
import Seo from "../../components/seo"

import MiniLawnChair from '../../images/miniLawnChair.svg'

const TheTitle = "Lawn Mowing Services"
const TheDescription = "Regular mowing and tending of your lawn is the best way to promote healthy, thick and robust grasses  capable of surviving long hot summers, preventing weeds and insects from moving in. A regularly tended  lawn will also need less fertilizer, weeding, thatching and aeration. Which will ultimately save you  money and provide you with an even better looking and more useful lawn! We will fit our visits to match  your schedule so it’s almost like your lawn magically just takes care of itself!"
const TheCanonical = "https://gardenescape.ca/services/lawn-mowing/"

const LawnMowing = () => {
	return (
		<Layout>
			<Seo
				title={TheTitle}
				description={TheDescription}
				canonical={TheCanonical} />
			<div className="serviceGrid">
				<StaticImage
					className="serviceGrid_boxA"
					src="../../images/home/grass.png"
					alt="background placeholder"
					objectFit="cover" objectPosition="50% 50%" />
				<div className="serviceGrid_boxB">
					<h1>{TheTitle}</h1>
					<p className="serviceGrid_boxB_text">25% off your first mow</p>
					<img
						src={MiniLawnChair}
						width="75px"
						height="75px"
						alt="garden escape mini logo" />
				</div>
			</div>
			<div className="mainWrapper my-16">
				<p>Regular mowing and tending of your lawn is the best way to promote healthy, thick and robust grasses  capable of surviving long hot summers, preventing weeds and insects from moving in. A regularly tended  lawn will also need less fertilizer, weeding, thatching and aeration. Which will ultimately save you  money and provide you with an even better looking and more useful lawn! We will fit our visits to match  your schedule so it’s almost like your lawn magically just takes care of itself!</p>
				<h2>HOW OFTEN SHOULD I MOW MY LAWN?</h2>
				<p>Mowing intervals strongly depends on many different factors. Some of those major factors are the type of  grass, use of the property, exposure and the season. All of these things affect the speed at which the  grass grows and the quality of that grass. Cutting too often or too much of the leaves can be  detrimental to the lawns overall health.</p>
				<h2>HOW DO I PROGRESS?</h2>
				<p>Contact us! We would be happy to provide you an obligation free estimate! We’ll put together a plan  that’ll best fit your yard goals and budget. Just contact us through our website, phone or email.</p>
				<p>Other ways we can serve you.</p>
				<p>We are happy to help you with all things related to beautifying your property, some other major services  we offer are; Fertilizing, Seasonal Yard cleanup, Hedge and Shrub pruning, Aeration and Power Raking,  Power washing and Junk Removal.</p>
			</div>
		</Layout>
	)
}

export default LawnMowing